<template>
    <div class="BlackScreen">
        <div id="RfbScreen" class="RfbScreen">
            <!-- This is where the remote screen will appear-->
        </div>
        <Menu :device="device" @chageQualityRDP="chageQualityRDP($event)"></Menu>
        <FireBridgeInfo :fireBridge="fireBridge" :loaded="rfb && rfb._rfbConnectionState === 'connected'"
            :onReconnect="connect" :onExit="exit" />
    </div>
</template>
<script>
import RFB from '@/sys/RdpCore/rfb';
import FireBridge from '@/sys/FireBridge/FireBrige'
import FireBridgeInfo from '@/views/FireBridgeInfo/FireBridgeInfo'
import Menu from '../Menu'

export default {
    components: { FireBridgeInfo, Menu },
    data() {
        return {
            rfb: null,
            desktopName: null,
            fireBridge: new FireBridge(this.device._id, "RDP"),
            lastStatus: null,
            showStatus: false,
            showFault: false,
            statusMsg: ""
        }
    },
    props: {
        device: {
            type: Object,
            default: null
        }
    },
    methods: {
        chageQualityRDP(e) {
            let quality = parseInt(e)
            this.rfb.qualityLevel = quality
            this.rfb.compressionLevel = quality
        },
        exit() {
            this.$router.push({ name: 'AcessType', params: { device: this.device } });
        },
        connectedToServer(e) {
            this.status("Connected to " + this.desktopName);
            this.rfb.sendCredentials()
        },
        disconnectedFromServer(e) {
            if (e.detail.clean) {
                this.status("Disconnected");
            } else {
                this.status("Something went wrong, connection is closed");
            }
        },
        credentialsAreRequired(e) {
            const password = prompt("Password Required:");
            console.log("Send senha");
            this.rfb.sendCredentials({ password: password });
            console.log("senha enviada");
        },
        updateDesktopName(e) {
            this.desktopName = e.detail.name;
        },
        sendCtrlAltDel() {
            this.rfb.sendCtrlAltDel();
            return false;
        },
        status(text) {
            //document.getElementById('status').textContent = text;
        },
        readQueryVariable(name, defaultValue) {
            // A URL with a query parameter can look like this:
            // https://www.example.com?myqueryparam=myvalue
            //
            // Note that we use location.href instead of location.search
            // because Firefox < 53 has a bug w.r.t location.search
            const re = new RegExp('.*[?&]' + name + '=([^&#]*)'),
                match = document.location.href.match(re);

            if (match) {
                // We have to decode the URL since want the cleartext value
                return decodeURIComponent(match[1]);
            }

            return defaultValue;
        },
        connect() {
            this.btnMenu = document.getElementById('btnMenu');
            let screen = document.getElementById('RfbScreen');
            //screen.deleteContents();             
            //this.$refs.sendCtrlAltDelButton.onclick = this.sendCtrlAltDel;            
            // | | |         | | |
            // | | | Connect | | |
            // v v v         v v v
            this.status("Connecting");
            // Build the websocket URL used to connect            
            // Creating a new RFB object will start a new connection
            this.rfb = new RFB(screen, { shared: true }, this.fireBridge);
            // Add listeners to important events from the RFB module
            this.rfb.addEventListener("connect", this.connectedToServer);
            this.rfb.addEventListener("disconnect", this.disconnectedFromServer);
            this.rfb.addEventListener("credentialsrequired", this.credentialsAreRequired);
            this.rfb.addEventListener("desktopname", this.updateDesktopName);
            // Set parameters that can be changed on an active connection
            this.rfb.viewOnly = false;//readQueryVariable('view_only', false);
            this.rfb.clipViewport = false;
            this.rfb.scaleViewport = true;
            this.rfb.resizeSession = false;
            this.rfb.qualityLevel = 6
            this.rfb.compressionLevel = 6
            this.rfb.showDotCursor = true;
            this.rfb.background = '#36393F';
        }
    },
    mounted() {
        if (this.device != null) {
            this.connect();
        } else {
            console.log("Faltou as informações do dispositivo");
            router.push({ path: '/ListOfDevices' });
        }
    },
    beforeUnmount() {
        console.log("Before unmount");
        this.rfb._disconnect();
    }
}  
</script>
<style scoped>
.BlackScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #36393F;
}

.RfbScreen {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
}
</style>